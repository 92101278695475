import { PluginKey, Plugin } from 'prosemirror-state';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { uploadImage } from '@/api/Cherry';
import ImageView from './Views/Image';
import { uuid as uuidGen } from 'vue-uuid';
// import { TextSelection } from 'prosemirror-state';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import { Node, nodeInputRule, mergeAttributes } from '@tiptap/core';

const IMAGE_INPUT_REGEX = /!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\)/;
export const imagePluginKey = new PluginKey('imagePlugin');

const Image = Node.create({
  name: 'image',

  inline: true,
  group: 'inline',
  draggable: true,
  selectable: true,

  addOptions: {
    errorCallback: () => {},
  },

  addAttributes() {
    return {
      src: {},
      width: {
        default: '10em',
        renderHTML: (attributes) => {
          return {
            style: `width: ${attributes.width}`,
          };
        },
      },
      mid: { default: '' },
      uuid: { default: '' },
      alt: {
        default: null,
      },
      title: {
        default: null,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: 'img[src]',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'img',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
    ];
  },

  addCommands() {
    return {
      setImage: (options) => ({ commands }) => {
        return commands.insertContent({
          type: this.name,
          attrs: options,
        });
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ImageView);
  },

  addInputRules() {
    return [
      nodeInputRule({
        find: IMAGE_INPUT_REGEX,
        type: this.type,
        getAttributes: (match) => {
          const [, , alt, src, title] = match;

          return { src, alt, title };
        },
      }),
    ];
  },
  addProseMirrorPlugins() {
    const ERROR_CALLBACK = this.options.errorCallback;
    const EVENT_ID = this.editor?.options?.meetingId;
    return [
      new Plugin({
        key: imagePluginKey,
        state: {
          // Initialize the plugin's internal state.
          init() {
            return DecorationSet.empty;
          },

          // Apply changes to the plugin state from a view transaction.
          apply(tr, value) {
            let set = value.map(tr.mapping, tr.doc);
            const action = tr.getMeta(imagePluginKey);
            if (action?.type === 'add') {
              const widget = createPlaceholder(action.file);
              const deco = Decoration.widget(action.pos, widget, {
                id: action.id,
              });
              set = set.add(tr.doc, [deco]);
            } else if (action?.type === 'remove') {
              set = set.remove(
                set.find(undefined, undefined, (spec) => spec.id === action.id)
              );
            }
            return set;
          },
        },

        props: {
          decorations(state) {
            return imagePluginKey.getState(state);
          },

          handlePaste(view, event) {
            const textData = event?.clipboardData?.getData('text/html');

            const clipboardItems = event?.clipboardData?.items;
            if (!clipboardItems) return false;
            const items = Array.from(clipboardItems).filter((item) => {
              // Filter the image items only
              return item.type.indexOf('image') !== -1;
            });
            if (items.length === 0) {
              return false;
            }

            const item = items[0];
            const image = item.getAsFile();
            if (!image) {
              return false;
            }
            if (event?.clipboardData?.types.includes('text/rtf')) {
              // Do not convert pasted rtf to image
              return false;
            }

            if (textData) {
              let doc = new DOMParser().parseFromString(textData, 'text/html');
              let image = doc.querySelector('img');
              if (!image?.src?.startsWith('file:///')) return false;
            }

            const reader = new FileReader();
            reader.onload = uploadCallback(EVENT_ID, view);
            reader.readAsDataURL(image);

            event.preventDefault();
            return true;
          },
          transformPastedHTML(html) {
            let doc = new DOMParser().parseFromString(html, 'text/html');
            let images = doc.querySelectorAll('img');

            if (images.length == 0) {
              return html;
            } else {
              images.forEach((img) => {
                if (img.src?.startsWith('file:///')) {
                  img.remove();
                }
              });

              return doc.body.innerHTML;
            }
          },
          handleDOMEvents: {
            drop(view, event) {
              const textData = event?.dataTransfer?.getData('text/html');
              if (textData) {
                // check for pasting html with local path
                let doc = new DOMParser().parseFromString(
                  textData,
                  'text/html'
                );
                let image = doc.querySelector('img');
                if (image?.src?.startsWith('file:///')) {
                  ERROR_CALLBACK({ type: 'localpath' });
                  return false;
                }
              }

              const hasFiles =
                event.dataTransfer &&
                event.dataTransfer.files &&
                event.dataTransfer.files.length;

              if (!hasFiles) {
                return;
              }

              const images = Array.from(
                event.dataTransfer.files
              ).filter((file) => /image/i.test(file.type));

              if (images.length === 0) {
                return;
              }

              const dropCoord = {
                left: event.clientX,
                top: event.clientY,
              };
              const reader = new FileReader();
              reader.onload = uploadCallback(EVENT_ID, view, dropCoord);
              reader.readAsDataURL(images[0]);
              event.preventDefault();
              event.stopPropagation();
              return true;
            },
          },
        },
        // appendTransaction: (_, oldState, newState) => {
        //   // delete image from server
        //   const imageNodesBefore = findChildrenByType(
        //     oldState.doc,
        //     oldState.schema.nodes.image
        //   );
        //   // just array of uuid
        //   const imageNodesAfter = findChildrenByType(
        //     newState.doc,
        //     newState.schema.nodes.image
        //   ).map((n) => n.node.attrs.uuid);

        //   imageNodesBefore.forEach((n) => {
        //     if (!imageNodesAfter.includes(n.node.attrs.uuid)) {
        //       // deleted
        //       console.log('deleted', n.node.attrs.uuid);
        //       deleteImage({ uuid: n.node.attrs.uuid, mid: n.node.attrs.mid });
        //     }
        //   });
        // },
      }),
    ];
  },
});

const findPlaceholder = (state, id) => {
  const decos = imagePluginKey.getState(state);
  const found = decos?.find(undefined, undefined, (spec) => spec.id === id);
  return found?.length ? found[0].from : undefined;
};

function uploadCallback(eventId, view, dropCoord = null) {
  return (readerEvent) => {
    const coordinates = dropCoord ? view.posAtCoords(dropCoord) : null;
    const id = uuidGen.v4();
    const { tr } = view.state;

    const imageMeta = {
      type: 'add',
      pos: coordinates?.pos || tr.selection.from,
      id: id,
      file: readerEvent.target.result,
    };
    tr.setMeta(imagePluginKey, imageMeta);
    view.dispatch(tr);

    uploadImage({
      file: readerEvent.target.result,
      mid: eventId,
    }).then(
      (r) => {
        if (!(r?.bucket && r?.uuid)) {
          view.dispatch(tr.setMeta(imagePluginKey, { type: 'remove', id: id }));
          return;
        }
        const placholderPos = findPlaceholder(view.state, id);
        if (placholderPos == null) return;
        const removeMeta = { type: 'remove', id: id };

        const url = `https://${r.bucket}.s3-${r.region}.amazonaws.com/${eventId}/${r.uuid}`;
        const node = view.state.schema.nodes.image.create({
          src: url,
          uuid: r.uuid,
          mid: eventId,
          width: '10em',
        });
        // coords for DROP, replace selection for PASTE
        let transaction = coordinates
          ? view.state.tr.insert(coordinates.pos, node)
          : view.state.tr.replaceSelectionWith(node);

        view.dispatch(transaction.setMeta(imagePluginKey, removeMeta));
      },
      () => {
        // On failure, just clean up the placeholder
        view.dispatch(tr.setMeta(imagePluginKey, { type: 'remove', id: id }));
      }
    );
  };
}

function createPlaceholder(fileAsStr) {
  const outer = document.createElement('span');
  outer.style.position = 'relative';
  outer.style.width = '10em';
  outer.style.display = 'inline-block';
  outer.style.lineHeight = '0';

  const img = document.createElement('img');
  img.src = fileAsStr;
  img.style.width = '100%';

  const loader = document.createElement('span');
  loader.classList.add('image-loader');
  outer.appendChild(img);
  outer.appendChild(loader);
  return outer;
}

export default Image;
