var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex rounded hover:bg-grey6 dark:hover:bg-base5 -ml-10 pl-10 -mr-10 pr-10 lg:-ml-3 lg:pl-3 lg:-mr-3 lg:pr-3 py-3 lg:pt-2.5 lg:pb-2",class:_vm.isMobile && _vm.showDetail ? 'bg-grey6' : '',on:{"click":_vm.showActionDetail}},[_c('div',{staticClass:"w-full flex flex-col lg:flex-row"},[_c('div',{staticClass:"w-full flex"},[_c('div',[_c('m-checkbox',{attrs:{"value":_vm.actionStatus,"filtercolour":false,"pending":_vm.action.action_pending},on:{"input":function($event){return _vm.onCompletedChange($event)}}})],1),_c('div',{staticClass:"ml-2 flex text-grey1 text-base justify-between w-full",staticStyle:{"margin-top":"-0.05rem"}},[_c('div',{class:_vm.actionStatus ? 'text-grey3 line-through actionCompleted' : '',attrs:{"data-recording-sensitive":""}},[_vm._t("default")],2)])]),_c('div',{staticClass:"lg:flex pl-6 lg:pl-0 lg:ml-2 text-base justify-end w-full font-medium'",class:_vm.detailHidden,staticStyle:{"margin-top":"-0.1rem"},attrs:{"data-recording-sensitive":""}},[_c('div',{staticClass:"text-grey1 text-left lg:text-right w-4/6 truncate mt-4 lg:mt-0",class:[
          _vm.groupBy != 'byMeeting' ? '' : 'lg:hidden',
          _vm.noLink ? '' : 'cursor-pointer' ],staticStyle:{"max-width":"10rem"},attrs:{"title":(_vm.eventDetail && _vm.eventDetail.summary
            ? _vm.eventDetail.summary
            : '(No title)') +
          ', ' +
          (_vm.eventDetail ? _vm.formatMeetingDate(_vm.eventDetail) : '')}},[(_vm.noLink)?_c('div',[_vm._v(" "+_vm._s(_vm.eventDetail && _vm.eventDetail.summary ? _vm.eventDetail.summary : '(No title)')+" ")]):_c('router-link',{attrs:{"to":{
            name: 'meeting',
            params: { id: _vm.eventDetail.id, calendarId: _vm.calendarId },
            query: { private: _vm.action.private ? null : undefined },
          }}},[_vm._v(" "+_vm._s(_vm.eventDetail && _vm.eventDetail.summary ? _vm.eventDetail.summary : '(No title)')+" ")])],1),_c('div',{staticClass:"flex w-2/6 lg:w-24 lg:ml-3 justify-end lg:justify-center"},[(_vm.action.action_duedate)?_c('div',{staticClass:"mt-4 lg:mt-0",class:_vm.eventStatus(_vm.action.action_duedate),staticStyle:{"min-width":"fit-content"},attrs:{"title":'Due ' + _vm.formatDateLong(_vm.action.action_duedate)}},[_vm._v(" "+_vm._s(_vm.formatDate(_vm.action.action_duedate))+" ")]):_c('div',{staticClass:"mt-4 lg:mt-0 text-grey3",staticStyle:{"min-width":"fit-content"}},[_vm._v(" No due date ")])])]),_c('div',{staticClass:"hidden lg:flex w-14 ml-3"},[(_vm.action.action_owner_email)?_c('div',{staticStyle:{"margin-top":"-0.1rem"}},[_c('meeting-guest',{attrs:{"guest":{ email: _vm.action.action_owner_email },"sizeComponent":"size2","showDetails":!_vm.noGuestHover}})],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }