<template>
  <div class="flex my-0.5 font-medium">
    <template v-if="id == 'action_item'">
      <div class="icon">
        <MCheckbox
          :value="true"
          :disabled="true"
          :filtercolour="false"
        ></MCheckbox>
      </div>
      <div class="label">
        {{ label }}
      </div>
    </template>
    <template v-else-if="id == 'agenda_item'">
      <div class="icon">
        <MRadioCheck :value="true" :disabled="true"></MRadioCheck>
      </div>
      <div class="label">
        {{ label }}
      </div>
    </template>
    <template v-else-if="id == 'bulletList'">
      <div class="icon" style="font-size: 20px; line-height: 16px">•</div>
      <div class="label">
        {{ label }}
      </div>
    </template>
    <template v-else-if="id == 'orderedList'">
      <div class="icon"><span style="margin-left: 1px">1.</span></div>
      <div class="label">
        {{ label }}
      </div>
    </template>
  </div>
</template>

<script>
import MCheckbox from '@/components/UI/MCheckbox';
import MRadioCheck from '@/components/UI/MRadioCheck';
export default {
  components: { MCheckbox, MRadioCheck },
  props: {
    id: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
div.icon {
  @apply text-center;
  width: 20px;
}
div.label {
  margin-left: 10px;
}
</style>
