import { Node, mergeAttributes } from '@tiptap/core';
import { Suggestion } from '../Plugins/Suggestion';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import DatetagView from './Views/Datetag';
import moment from 'moment';
// import { InputRule } from 'prosemirror-inputrules';

const Datetag = Node.create({
  name: 'datetag',

  addOptions() {
    return {
      dateFormat: 'DD/MM/YYYY',
      removeDateFromDatetagLabel(label) {
        const re = / [(]?[0-9]{2}\/[0-9]{2}\/[0-9]{4}[)]?$/;
        return label.replace(re, '');
      },

      HTMLAttributes: { class: 'datetag' },
      renderLabel({ node }) {
        return `${this.removeDateFromDatetagLabel(node.attrs.label)} ${moment(
          node.attrs.id
        ).format(this.dateFormat)}`;
      },

      suggestion: {
        char: 'by ',
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          let cmd = editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ]);

          if (props.openPicker) {
            cmd.setNodeSelection(range.from);
          }
          cmd.run();
        },
        allow: ({ editor, range }) => {
          const $from = editor.state.doc.resolve(range.from);
          const type = editor.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
        shouldSuggest: ($from) => {
          // suggest only in action items
          return $from.node(-1)?.type?.name == 'action_item';
        },
        allowSpaces: true,
        matchLimitFn: (match) => {
          // this fn further limit if suggestion regex match or not
          const splittedBySpace = match ? match.query.split(' ') : [];
          const spaceCnt = match ? splittedBySpace.length - 1 : 0;
          const queryLen = match ? match.query.length : 0;

          const maxSpaces =
            splittedBySpace.length > 1 && splittedBySpace[0] == 'next' ? 2 : 1;
          // only 1 space or 2 spaces if we start with "next"
          // max 20 chars
          return spaceCnt < maxSpaces && queryLen < 20;
        },
        suggestionType: 'datetag',
      },
    };
  },

  group: 'inline',
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(DatetagView);
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
        { title: moment(node.attrs.id).format('dddd, MMMM DD, YYYY') }
      ),
      this.options.renderLabel({ node }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({ node });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isDatetag = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isDatetag = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize
              );

              return false;
            }
          });

          return isDatetag;
        }),
    };
  },

  addCommands() {
    return {
      addDatetag: () => ({ state, dispatch }) => {
        const { selection } = state;
        const position = selection.$from.pos;
        const char = selection.$cursor.doc.textBetween(
          position - 1,
          position,
          '\0',
          '\0'
        );

        let addChar = '';
        // add space in front of matching char
        if (!(char === ' ' || selection.$from.start() === position))
          addChar = ' ';

        const tr = state.tr.insertText(
          addChar + this.options.suggestion.char,
          position
        );
        dispatch(tr);
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default Datetag;
