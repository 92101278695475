import { Extension } from '@tiptap/core';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';
const ClassDecorator = Extension.create({
  name: 'class-decorator',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc }) => {
            const decorations = [];

            doc.descendants((node, pos) => {
              switch (node.type?.name) {
                case 'listItem':
                  if (node.firstChild?.type?.name === 'action_item') {
                    decorations.push(
                      Decoration.node(pos, pos + node.nodeSize, {
                        class: 'actionitem-child specialitem-child',
                      })
                    );
                  } else if (node.firstChild?.type?.name === 'agenda_item') {
                    decorations.push(
                      Decoration.node(pos, pos + node.nodeSize, {
                        class: 'agendaitem-child specialitem-child',
                      })
                    );
                  }
                  break;
                // case 'agenda_item':
                //   if (node.firstChild?.type?.name == 'heading') {
                //     console.log('headinfg', pos);
                //     decorations.push(
                //       Decoration.node(pos, pos + node.nodeSize, {
                //         class: `with-h${node.firstChild?.attrs.level}`,
                //       })
                //     );
                //   }
                //   break;
              }
            });
            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
});

export default ClassDecorator;
