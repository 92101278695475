import { Node, mergeAttributes } from '@tiptap/core';

const SpecialItem = Node.create({
  name: 'special_item',
  group: 'specialblock',
  content: 'paragraph',
  draggable: true,
  selectable: true,
  defining: true,

  addAttributes() {
    return {
      uuid: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-uuid'),
        renderHTML: () => {
          // if (!attributes.uuid) {
          //   return {};
          // }

          // return {
          //   'data-uuid': attributes.uuid,
          // };
          return {};
        },
      },
      completed: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => {
          const completed = element.getAttribute('data-completed');
          return completed == 'true';
        },
        renderHTML: (attributes) => {
          // return both T/F as we test both in RE in backend
          return {
            'data-completed': attributes.completed,
          };
        },
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: `div[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      mergeAttributes(
        {
          'data-type': this.name,
        },
        this.options?.HTMLAttributes,
        HTMLAttributes
      ),
      0,
    ];
  },
});

export default SpecialItem;
