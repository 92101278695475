<template>
  <div class="text-base2" :class="suggestionClass" data-recording-sensitive>
    <m-text-modal>
      <template v-if="items.all.length > 0">
        <div
          v-for="(item, index) in items.all"
          :key="item.idList"
          class="item-row"
          @click="selectItem(index)"
        >
          <div
            class="item"
            :class="{
              'is-selected': selectedIndex === index,
            }"
          >
            <template v-if="suggestionType == 'command'">
              <suggestion-list-command-item
                :id="item.idList"
                :label="item.nameList"
              ></suggestion-list-command-item>
            </template>
            <template v-else>
              {{ item.nameList }}
              <span
                v-if="suggestionType == 'datetag' && !item.noDateSuffix"
                style="font-size: 0.7rem"
                >{{ formatSuggestionDate(item.idList) }}</span
              >
            </template>
          </div>
        </div>
      </template>
      <div v-else class="no-results">No items found</div>
    </m-text-modal>
  </div>
</template>

<script>
import MTextModal from '@/components/UI/MTextModal';
import moment from 'moment';
import SuggestionListCommandItem from './SuggestionListCommandItem.vue';

export default {
  components: {
    MTextModal,
    SuggestionListCommandItem,
  },
  props: {
    items: {
      type: Object,
      required: true,
    },

    command: {
      type: Function,
      required: true,
    },

    suggestionType: {
      type: String,
      default: '',
    },
    text: {
      type: String,
      default: '',
    },
    dateFormat: {
      type: String,
      default: 'MM/DD/YYYY',
    },
  },

  data() {
    return {
      selectedIndex: 0,
    };
  },

  watch: {
    items() {
      this.selectedIndex = 0;
    },
    text() {
      if (this.text == '@@' && this.items.me != null) {
        this.insertItem(this.items.me);
      }
    },
  },
  mounted() {
    if (this.suggestionType == 'command') {
      // track slash command when used
      window.meetric.track('Slash command', {});
    }
  },

  computed: {
    suggestionClass() {
      if (this.suggestionType == 'mention') {
        return 'mention-suggestion';
      } else if (this.suggestionType == 'datetag') {
        return 'datetag-suggestions text-grey2';
      } else if (this.suggestionType == 'command') {
        return 'commands-suggestions text-grey1';
      }

      return '';
    },
  },

  methods: {
    formatSuggestionDate(date) {
      return moment(date).format(this.dateFormat);
    },
    onKeyDown({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        this.downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        this.enterHandler();
        return true;
      }

      return false;
    },

    upHandler() {
      this.selectedIndex =
        (this.selectedIndex + this.items.all.length - 1) %
        this.items.all.length;
    },

    downHandler() {
      this.selectedIndex = (this.selectedIndex + 1) % this.items.all.length;
    },

    enterHandler() {
      this.selectItem(this.selectedIndex);
    },

    selectItem(index) {
      const item = this.items.all[index];

      if (item) {
        this.insertItem(item);
      }
    },
    insertItem(item) {
      this.command({
        id: item.idInsert ? item.idInsert : item.idList,
        label: item.nameInsert ? item.nameInsert : item.nameList,
        openPicker: item.openPicker ? true : null,
        item: item,
      });
    },
  },
};
</script>

<style>
.dark .textmodal .item.is-selected,
.dark .textmodal .item:hover {
  background-color: #494949;
}

.textmodal .item.is-selected,
.textmodal .item:hover {
  /* @apply bg-base2; */
  /* 8% bondi-blue */
  background-color: rgba(0, 164, 189, 0.08);
}
.datetag-suggestions .item.is-selected,
.datetag-suggestions .item:hover,
.commands-suggestions .item.is-selected,
.commands-suggestions .item:hover {
  @apply bg-grey6  bg-opacity-80;
}

.commands-suggestions .item-row:not(:last-child) {
  @apply border-grey6 border-b;
}

/* Suggestion list */
.textmodal .item {
  @apply px-3 py-1 cursor-pointer truncate;
}
.textmodal .item:last-child {
  margin-bottom: 0;
}

.textmodal .item.is-selected,
.textmodal .item:hover {
  @apply rounded-md;
}

.textmodal .item.is-empty {
  @apply opacity-50;
}

.tippy-box[data-theme~='meetric-suggestion'] {
  all: unset;
}
.tippy-box[data-theme~='meetric-suggestion'] .tippy-content {
  padding: 0;
  text-align: left;
}
</style>
