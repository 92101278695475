import { Plugin } from 'prosemirror-state';
import { Slice, Fragment } from 'prosemirror-model';

export function SpecialItemPaste() {
  const handler = (fragment) => {
    const nodes = [];

    fragment.forEach((child) => {
      if (
        !!child.type &&
        (child.type.name === 'action_item' ||
          child.type.name === 'agenda_item') &&
        child.attrs.uuid
      ) {
        // reset uuid to null so it is generated same as for new
        let changed = child.copy(child.content);
        const attrs = { ...child.attrs, uuid: null };
        if (child.attrs.remind) {
          attrs.remind = false;
          attrs.remindDate = '';
        }

        changed.attrs = attrs;
        nodes.push(changed);
      } else nodes.push(child);
    });

    return Fragment.fromArray(nodes);
  };

  return new Plugin({
    props: {
      transformPasted: (slice) =>
        new Slice(handler(slice.content), slice.openStart, slice.openEnd),
    },
  });
}
