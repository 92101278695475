import ActionItemView from './Views/ActionItem';

import { VueNodeViewRenderer } from '@tiptap/vue-2';

import { wrappingInputRule } from '@tiptap/core';
import SpecialItem from './SpecialItem';

const ActionItem = SpecialItem.extend({
  name: 'action_item',
  group: 'actionblock',

  addAttributes() {
    return {
      ...this.parent?.(),

      dueDate: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-dueDate'),

        renderHTML: (attributes) => {
          if (!attributes.dueDate) {
            return {};
          }

          return {
            'data-dueDate': attributes.dueDate,
          };
        },
      },
      remind: {
        default: false,
        keepOnSplit: false,
        parseHTML: (element) => {
          const remind = element.getAttribute('data-remind');
          return remind == 'true';
        },
        renderHTML: (attributes) => {
          if (!attributes.remind) {
            return {};
          }

          return {
            'data-remind': attributes.remind,
          };
        },
      },
      remindDate: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-remindDate'),
        renderHTML: (attributes) => {
          if (!attributes.remindDate) {
            return {};
          }

          return {
            'data-remindDate': attributes.remindDate,
          };
        },
      },
      owner: {
        default: null,
        keepOnSplit: false,
        parseHTML: (element) => element.getAttribute('data-owner'),
        renderHTML: (attributes) => {
          if (!attributes.owner) {
            return {};
          }

          return {
            'data-owner': attributes.owner,
          };
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(ActionItemView);
  },

  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.commands.splitSpecialItem(this.name),
      Backspace: () => {
        return this.editor.commands.deleteSpecialItem(this.name);
      },
    };
  },

  addInputRules() {
    return [wrappingInputRule({ find: /^\s*(\[\])$/, type: this.type })];
  },

  addCommands() {
    return {
      toggleActionItem: () => ({ editor, chain }) => {
        let ch = chain();
        if (editor.isActive('agenda_item')) {
          // if we toggle in action item, untoggle first
          ch = ch.toggleAgendaItem();
        }
        return ch.toggleWrap(this.type).run();
      },
      // set to agendaItem or do nothing
      setActionItem: () => ({ editor, commands }) => {
        if (editor.isActive('action_item')) {
          return false;
        }
        return commands.toggleActionItem();
      },
      // unset to actionItem or do nothing
      unsetActionItem: () => ({ editor, commands }) => {
        if (editor.isActive('action_item')) {
          return commands.toggleActionItem();
        }
        return false;
      },
    };
  },
});

export default ActionItem;
