<template>
  <node-view-wrapper as="span">
    <span
      style="
        position: relative;
        display: inline-block;
        line-height: 0;
        max-width: 100%;
        width: 10em;
      "
      :style="{ width: cwidth }"
      class="img-in-wrapper"
      ref="outer"
      data-drag-handle
    >
      <img
        :src="csrc"
        style="width: 100%"
        @click="makeNodeSelection"
        :class="{ 'ProseMirror-selectednode': selected }"
      />
      <span
        v-show="selected"
        ref="handle"
        style="
          position: absolute;
          bottom: 0px;
          right: 0px;
          width: 10px;
          height: 10px;
          border-top: none;
          border-right: 3px solid black;
          border-bottom: 3px solid black;
          border-left: none;
          border-image: initial;
          cursor: nwse-resize;
        "
      ></span>
    </span>
  </node-view-wrapper>
</template>

<script>
import { NodeSelection } from 'prosemirror-state';
import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2';

export default {
  props: nodeViewProps,
  components: {
    NodeViewWrapper,
  },
  computed: {
    csrc: {
      get() {
        return this.node.attrs.src;
      },
      set(src) {
        this.updateAttributes({ src });
      },
    },
    cwidth: {
      get() {
        return this.node.attrs.width || '10em';
      },
      set(width) {
        this.updateAttributes({ width });
      },
    },
  },
  methods: {
    makeNodeSelection() {
      const { state } = this.editor.view;
      let tr = state.tr;
      const selection = NodeSelection.create(state.doc, this.getPos());
      tr = tr.setSelection(selection);
      this.editor.view.dispatch(tr);
    },
    getFontSize(element) {
      return parseFloat(getComputedStyle(element).fontSize);
    },
    resizeHandle(e) {
      e.preventDefault();

      const startX = e.pageX;

      const fontSize = this.getFontSize(this.$refs.outer);
      const realWidth =
        getComputedStyle(this.$refs.outer).width.match(/(.+)px/)[1] / fontSize;

      const onMouseMove = (e) => {
        const currentX = e.pageX;

        const diffInPx = currentX - startX;
        const diffInEm = diffInPx / fontSize;

        this.$refs.outer.style.width = `${realWidth + diffInEm}em`;
      };

      const onMouseUp = (e) => {
        e.preventDefault();

        document.removeEventListener('mousemove', onMouseMove);
        document.removeEventListener('mouseup', onMouseUp);

        this.cwidth = this.$refs.outer.style.width;
      };

      document.addEventListener('mousemove', onMouseMove);
      document.addEventListener('mouseup', onMouseUp);
    },
  },
  mounted() {
    this.$refs.handle.onmousedown = this.resizeHandle;
  },
};
</script>
<style scoped>
.img-in-wrapper {
  @apply border-l-2 border-r-2 border-transparent;
}

.ProseMirror-selectednode {
  outline: 2px solid #8cf;
}
</style>
