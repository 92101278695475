<template>
  <node-view-wrapper
    @click.native="onMentionClick"
    as="span"
    class="mention cursor-pointer"
    :class="{
      readonly: !editor.isEditable,
      myself: node.attrs.id == extension.options.myEmail,
    }"
    contenteditable="false"
    data-recording-sensitive
    :data-id="node.attrs.id"
    :data-label="node.attrs.label"
    :data-type="node.type.name"
    >{{
      extension.options.renderLabel({ options: extension.options, node })
    }}</node-view-wrapper
  >
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-2';
import tippy, { sticky } from 'tippy.js';
import SuggestionList from './SuggestionList';
import { posToDOMRect } from '@tiptap/core';
import { VueRenderer } from '@tiptap/vue-2';

export default {
  props: {
    editor: {
      type: Object,
    },
    node: {
      type: Object,
    },
    extension: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    getPos: {
      type: Function,
    },
    updateAttributes: {
      type: Function,
    },
  },
  data() {
    return { component: null, popup: null };
  },
  components: {
    NodeViewWrapper,
  },
  computed: {},
  watch: {
    selected: function (val) {
      // console.log('sel', val);
      if (!val) this.cleanup();
    },
  },
  methods: {
    cleanup() {
      this.popup?.[0].destroy();
      this.component?.destroy();
      this.component = null;
      this.popup = null;
    },
    async onMentionClick() {
      this.component = new VueRenderer(SuggestionList, {
        parent: this,
        propsData: {
          items: await this.extension.options.suggestion.items(''),
          command: ({ id, label }) => {
            this.updateAttributes({
              id,
              label,
            });
            this.cleanup();
          },
          suggestionType: 'mention',
        },
      });

      this.popup = tippy('body', {
        getReferenceClientRect: () =>
          posToDOMRect(this.editor.view, this.getPos(), this.getPos()),
        appendTo: () => document.body,
        theme: 'meetric-suggestion',
        content: this.component.element,
        arrow: false,
        interactive: true,
        showOnCreate: true,
        sticky: true, // make sure position of tippy is updated when content changes
        plugins: [sticky],
        trigger: 'manual',
        placement: 'bottom-start',
        inertia: true,
        duration: [400, 200],
      });
    },
  },
  mounted() {},
  beforeDestroy() {
    this.cleanup();
  },
};
</script>
<style scoped></style>
