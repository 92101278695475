<template>
  <div
    class="card-shadow menufilter bg-white dark:bg-base5 lg:mt-0"
    :class="
      highlight
        ? ' border-0 border-base2 border-b lg:border-b-2 lg:border-t-2 lg:border-opacity-50'
        : ' border border-grey6 border-l-0 border-r-0'
    "
  >
    <div
      class="border-0 py-2"
      :class="
        highlight
          ? ''
          : 'lg:border-b lg:border-t lg:border-white lg:dark:border-base5'
      "
    >
      <div
        class="m-auto lg:w-4/5 pl-8 lg:pl-0"
        style="margin-top:0.24rem;margin-bottom:0.24rem;"
      >
        <div class="flex flex-col lg:flex-row justify-between">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MenuPanel',
  components: {},
  props: {
    highlight: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.card-shadow {
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.8);
  -webkit-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.5);
  -moz-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(224, 241, 250, 0.5);
}
.dark .card-shadow {
  box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 14, 5, 0.8);
  -webkit-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 14, 5, 0.5);
  -moz-box-shadow: 0rem 0.1rem 0.2rem 0rem rgba(31, 14, 5, 0.5);
}
</style>
