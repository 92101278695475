<template>
  <div
    class="flex rounded hover:bg-grey6 dark:hover:bg-base5 -ml-10 pl-10 -mr-10 pr-10 lg:-ml-3 lg:pl-3 lg:-mr-3 lg:pr-3 py-3 lg:pt-2.5 lg:pb-2"
    :class="isMobile && showDetail ? 'bg-grey6' : ''"
    @click="showActionDetail"
  >
    <div class="w-full flex flex-col lg:flex-row">
      <div class="w-full flex">
        <div>
          <m-checkbox
            :value="actionStatus"
            :filtercolour="false"
            @input="onCompletedChange($event)"
            :pending="action.action_pending"
          ></m-checkbox>
        </div>

        <div
          class="ml-2 flex text-grey1 text-base justify-between w-full"
          style="margin-top: -0.05rem"
        >
          <div
            :class="
              actionStatus ? 'text-grey3 line-through actionCompleted' : ''
            "
            data-recording-sensitive
          >
            <slot></slot>
          </div>
        </div>
      </div>
      <div
        :class="detailHidden"
        class="lg:flex pl-6 lg:pl-0 lg:ml-2 text-base justify-end w-full font-medium'"
        style="margin-top: -0.1rem"
        data-recording-sensitive
      >
        <div
          :class="[
            groupBy != 'byMeeting' ? '' : 'lg:hidden',
            noLink ? '' : 'cursor-pointer',
          ]"
          class="text-grey1 text-left lg:text-right w-4/6 truncate mt-4 lg:mt-0"
          style="max-width: 10rem"
          :title="
            (eventDetail && eventDetail.summary
              ? eventDetail.summary
              : '(No title)') +
            ', ' +
            (eventDetail ? formatMeetingDate(eventDetail) : '')
          "
        >
          <div v-if="noLink">
            {{
              eventDetail && eventDetail.summary
                ? eventDetail.summary
                : '(No title)'
            }}
          </div>
          <router-link
            v-else
            :to="{
              name: 'meeting',
              params: { id: eventDetail.id, calendarId: calendarId },
              query: { private: action.private ? null : undefined },
            }"
          >
            {{
              eventDetail && eventDetail.summary
                ? eventDetail.summary
                : '(No title)'
            }}
          </router-link>
        </div>
        <div class="flex w-2/6 lg:w-24 lg:ml-3 justify-end lg:justify-center">
          <div
            v-if="action.action_duedate"
            style="min-width: fit-content"
            class="mt-4 lg:mt-0"
            :class="eventStatus(action.action_duedate)"
            :title="'Due ' + formatDateLong(action.action_duedate)"
          >
            {{ formatDate(action.action_duedate) }}
          </div>
          <div
            v-else
            style="min-width: fit-content"
            class="mt-4 lg:mt-0 text-grey3"
          >
            No due date
          </div>
        </div>
      </div>
      <div class="hidden lg:flex w-14 ml-3">
        <div v-if="action.action_owner_email" style="margin-top: -0.1rem">
          <meeting-guest
            :guest="{ email: action.action_owner_email }"
            sizeComponent="size2"
            :showDetails="!noGuestHover"
          ></meeting-guest>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { getDatetimeFromEvent } from '@/components/Utils';
import MCheckbox from '@/components/UI/MCheckbox';
import MeetingGuest from '@/components/MeetingGuest/MeetingGuest';

export default {
  name: 'ActionItemBlock',
  components: {
    MCheckbox,
    MeetingGuest,
  },

  props: {
    action: {
      type: Object,
      required: true,
    },
    eventDetail: {
      type: Object,
      required: true,
      default: () => {},
    },
    calendarId: {
      type: String,
      required: true,
    },
    groupBy: {
      type: String,
      required: false,
    },
    showDetail: {
      type: Boolean,
      required: false,
      default: false,
    },
    noLink: {
      type: Boolean,
      required: false,
      default: false,
    },
    noGuestHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      actionStatus: false,
    };
  },
  watch: {
    'action.update_counter': {
      // we need counter because we can't watch just action_completed
      // if there is server error, action_completed wouldn't trigger this
      immediate: true,
      handler() {
        this.actionStatus = this.action.action_completed;
      },
    },
  },

  mounted() {},
  computed: {
    isMobile() {
      return this.$store?.getters['isMobile'];
    },
    detailHidden() {
      return this.showDetail ? 'flex ' : 'hidden';
    },
  },
  methods: {
    showActionDetail() {
      this.$emit('actiondetailshown', this.action.action_id);
    },
    eventStatus: function (event) {
      const today = moment().startOf('day');
      const deadline = moment(event).startOf('day');
      if (today > deadline) {
        return 'text-alerterror';
      } else if (today < deadline) {
        return 'text-grey3';
      } else {
        return 'text-alertwarning';
      }
    },
    formatDate: function (dateStr) {
      return dateStr == 'None' ? dateStr : moment(dateStr).format('ddd, MMM D');
    },
    formatDateLong: function (dateStr) {
      return dateStr == 'None'
        ? dateStr
        : moment(dateStr).format('dddd, MMM D YYYY');
    },
    formatMeetingDate: function (eventDetail) {
      return this.formatDateLong(getDatetimeFromEvent(eventDetail));
    },
    // when you change checkbox, emit it to parent so it can sync with editor
    onCompletedChange: function (completed) {
      // event is T/F representing ticking ckbox

      this.actionStatus = completed;

      if (completed != this.action.action_completed) {
        this.$emit('completedchange', {
          actionID: this.action.action_id,
          isPrivate: this.action.private,
          completed: completed,
          docID: this.action.doc_id,
        });
      }
    },
  },
};
</script>

<style>
.mention {
  @apply text-base2;
}

.actionCompleted .mention,
.actionCompleted .datetag {
  @apply text-grey4;
}

/* Hover on action row in action list */
/* .dark .action-row:hover {
  background-color: #242424;
} */
</style>
