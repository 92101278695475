import { Extension } from '@tiptap/core';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';
import { setReminder, deleteReminder } from '@/api/Cherry';

function onDecoClick({
  eState,
  view,
  uuid,
  isPrivate,
  calendarId,
  meetingId,
  meetingSummary,
  inRelatedMeeting,
}) {
  return function () {
    const newTr = eState.tr;

    if (view.editable) {
      // toggle remind for specific action item
      eState.doc.descendants((node, pos) => {
        if (
          !!node.type &&
          node.type.name === 'action_item' &&
          node.attrs.uuid === uuid
        ) {
          let { remind, ...rest } = node.attrs;
          // toggle reminder
          newTr.setNodeMarkup(pos, undefined, {
            ...rest,
            remind: !remind,
          });

          if (!remind) {
            setReminder(
              {
                remind_at: node.attrs.remindDate,
                meeting_summary: meetingSummary,
                private: isPrivate,
              },
              calendarId,
              meetingId,
              uuid
            );
          } else {
            deleteReminder(calendarId, meetingId, uuid);
          }

          view.dispatch(newTr);

          const props = {
            value: !remind,
            inRelatedMeeting: inRelatedMeeting,
            isPrivate: isPrivate,
            action_uuid: uuid,
            meeting_id: meetingId,
          };

          window.meetric.track('Toggle action reminder', props);
        }
      });
    }
  };
}

function buildBell({
  eState,
  view,
  uuid,
  isPrivate,
  remind,
  calendarId,
  meetingId,
  meetingSummary,
  inRelatedMeeting,
}) {
  const bell = document.createElement('span');
  const colour = remind ? 'text-base3' : 'text-grey3';
  bell.title = remind ? 'Turn email reminder off' : 'Turn email reminder on';
  bell.innerHTML = `<svg
      xmlns='http://www.w3.org/2000/svg'
      class=" ml-1 stroke-current ${colour}"
      width='16'
      height='16'
      viewBox='0 0 24 24'
      stroke-width='1.5'
      fill='none'
      stroke-linecap='round'
      stroke-linejoin='round'
    >
      <path stroke='none' d='M0 0h24v24H0z' fill='none' />
      <path d='M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6' />
      <path d='M9 17v1a3 3 0 0 0 6 0v-1' />
      <path d='M21 6.727a11.05 11.05 0 0 0 -2.794 -3.727' />
      <path d='M3 6.727a11.05 11.05 0 0 1 2.792 -3.727' />
    </svg>`;
  bell.classList.add('inline-block');
  if (view.editable) bell.classList.add('cursor-pointer');
  bell.style.verticalAlign = 'sub';
  bell.onmousedown = onDecoClick({
    eState,
    view,
    uuid,
    isPrivate,
    calendarId,
    meetingId,
    meetingSummary,
    inRelatedMeeting,
  });

  return bell;
}

const Reminder = Extension.create({
  name: 'reminder',

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          // Setup decorator on the currently active suggestion.
          decorations: (eState) => {
            let decos = [];

            eState.doc.descendants((node0, pos0) => {
              // find all actionitems
              // check if you have owner and date, if yes, find first date and decorate it
              // use ai remind value T/F for color
              if (
                !!node0.type &&
                node0.type.name === 'action_item' &&
                !!node0.attrs.owner &&
                !!node0.attrs.dueDate
              ) {
                // there is owner and due date in aitem
                let bellShowed = false;
                node0.descendants((node, pos) => {
                  if (
                    !!node.type &&
                    node.type.name === 'datetag' &&
                    !bellShowed
                  ) {
                    bellShowed = true;
                    decos.push(
                      Decoration.widget(
                        pos + pos0 + node.nodeSize + 1,
                        buildBell({
                          eState: eState,
                          view: this.editor.view,
                          uuid: node0.attrs.uuid,
                          isPrivate: this.editor.options.isPrivate,
                          remind: node0.attrs.remind,
                          calendarId: this.editor.options.calendarId,
                          meetingId: this.editor.options.meetingId,
                          meetingSummary: this.editor.options.summary,
                          inRelatedMeeting: this.editor.options
                            .inRelatedMeeting,
                        }),
                        {
                          ignoreSelection: true,
                        }
                      )
                    );
                  }
                });
              }
            });

            return DecorationSet.create(eState.doc, decos);
          },
        },
      }),
    ];
  },
});

export default Reminder;
