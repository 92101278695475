<template>
  <div class="hidden lg:flex justify-between w-4/5 mx-auto h-0 lg:h-40">
    <div class="flex flex-grow my-auto">
      <!-- Profile pic -->

      <component
        :is="linkToProfile ? 'router-link' : 'div'"
        :to="{ name: 'profile' }"
        class="my-auto mr-5"
      >
        <div
          class="flex overflow-hidden rounded-full bg-alertvalidation items-center justify-center relative"
          style="width: 3.4rem; height: 3.4rem"
        >
          <svg
            class="h-full w-full absolute"
            preserveAspectRatio="none"
            viewBox="0 0 100 100"
          >
            <!-- Move, Curve (control, control, dest), Line, Fill -->
            <path
              d="M-31.31,57.72 C51.06,72.53 47.11,16.28 105.25,29.11 L116.53,-112.00 L-34.14,-55.75 Z"
              opacity="0.3"
              fill-opacity="null"
              stroke-opacity="null"
              fill="#fff"
            />
          </svg>
          <div v-if="!profilePic" class="text-white uppercase font-medium">
            {{ givenName.slice(0, 1) }}
          </div>
          <div
            v-if="profilePic"
            class="rounded-full bg-cover bg-center z-10"
            style="width: 3rem; height: 3rem"
            :style="'background-image: url(' + profilePic + ')'"
          ></div>
        </div>
      </component>

      <div style="margin-top: -0.15rem">
        <h3 class="greeting-msg font-semibold text-base1 text-3xl">
          {{ greetingMessage() }},
          <span data-recording-sensitive>{{ givenName }}</span>
        </h3>
        <div
          v-if="actionsToDoNum > -1 && meetingsToday > -1"
          class="font-medium text-grey2 text-base"
        >
          You have
          <router-link :to="{ name: 'meetings' }">
            <span class="text-base2" data-recording-sensitive>{{
              meetingsToday
            }}</span>
          </router-link>
          {{ meetingsToday == 1 ? 'meeting' : 'meetings' }} today and
          <router-link :to="{ name: 'actions' }">
            <span class="text-base2" data-recording-sensitive>{{
              actionsToDoNum
            }}</span>
          </router-link>
          {{ actionsToDoNum == 1 ? 'action' : 'actions' }} to do
        </div>
        <m-placeholder
          v-else
          class="h-4 rounded-lg my-auto w-full"
        ></m-placeholder>
      </div>
    </div>
    <div v-if="hasSlot" class="my-auto">
      <slot></slot>
    </div>
    <div v-else class="my-auto">
      <router-link :to="{ name: 'referral' }">
        <m-button
          class="m-3"
          button-style="btn-primary"
          segmentName="Invite to Meetric"
          :segmentProps="{ from: 'User header' }"
          title="Send colleagues a Meetric invite via email"
          icon="👩🏼‍💻"
        >
          Invite people to Meetric
        </m-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import MButton from '@/components/UI/MButton';
import MPlaceholder from '@/components/UI/MPlaceholder';
import moment from 'moment';
import { getMyActionCount } from '@/api/Cherry';
import { getAllCalendarEvents } from '@/api/Google';
import { getMeetingAttendees } from '@/components/Utils';

export default {
  name: 'UserHeader',
  components: {
    MButton,
    MPlaceholder,
  },
  computed: {
    hasSlot() {
      return !!this.$slots.default;
    },
    calendarIds() {
      return this.$store.getters['selectedCalendars'];
    },
    showSolo() {
      return this.$store.getters['preferenceShowSolo'];
    },
    showAllDay() {
      return this.$store.getters['preferenceShowAllDay'];
    },
    givenName() {
      return this.$gAuth.basicProfile.getGivenName();
    },
    profilePic() {
      return this.$gAuth.basicProfile.getImageUrl();
    },
  },
  data() {
    return {
      actionsToDoNum: -1,
      meetingsToday: -1,
      linkToProfile: true,
    };
  },
  mounted() {
    this.loadActionCount();
    this.loadMeetingCount();
    this.greetingMessage();

    this.linkToProfile = this.$route.name != 'profile';
  },
  watch: {
    calendarIds: function () {
      this.meetingsToday = -1;
      this.loadMeetingCount();
    },
  },
  methods: {
    greetingMessage() {
      const dayTime = moment().format('HH');
      if (dayTime > 0 && dayTime < 12) {
        return 'Good morning';
      } else if (dayTime >= 12 && dayTime < 18) {
        return 'Good afternoon';
      } else {
        return 'Good evening';
      }
    },
    loadActionCount() {
      getMyActionCount().then((data) => {
        this.actionsToDoNum = data.action_count;
      });
    },
    loadMeetingCount() {
      const gParams = {
        timeMax: moment(new Date()).endOf('day').format(),
        timeMin: moment(new Date()).startOf('day').format(),
      };
      getAllCalendarEvents(this.calendarIds, gParams).then((events) => {
        // count meetings today and respect pref for allDay and Solo
        let cnt = 0;

        for (let i = 0; i < events.length; i++) {
          const attendees = getMeetingAttendees(events[i].event);

          if (!this.showSolo) {
            if (attendees.length <= 1) continue;
          }
          if (!this.showAllDay) {
            if (events[i].event.start.date) continue;
          }

          cnt++;
        }
        this.meetingsToday = cnt;
      });
    },
  },
};
</script>

<style scoped></style>
