<template>
  <!-- <node-view-wrapper> -->
  <node-view-wrapper
    class="flex action-item flex-grow"
    :data-type="node.type.name"
    :data-uuid="node.attrs.uuid"
    :data-dueDate="node.attrs.dueDate"
    :data-completed="node.attrs.completed.toString()"
    :data-remind="node.attrs.remind.toString()"
    :data-remindDate="node.attrs.remindDate"
    :data-owner="node.attrs.owner"
    :class="{
      completed: node.attrs.completed,
      'action-item-empty': node.content.size <= 2,
    }"
  >
    <MCheckbox
      data-drag-handle
      class="todo-checkbox"
      contenteditable="false"
      v-bind:value="node.attrs.completed"
      v-bind:filtercolour="false"
      :disabled="!editor.isEditable"
      @input="onChange"
    ></MCheckbox>
    <node-view-content
      class="todo-content"
      data-recording-sensitive
      :contenteditable="editor.isEditable"
    />
  </node-view-wrapper>
  <!-- </node-view-wrapper> -->
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2';
import MCheckbox from '@/components/UI/MCheckbox';

export default {
  components: { MCheckbox, NodeViewWrapper, NodeViewContent },
  props: {
    editor: {
      type: Object,
    },
    node: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    getPos: {
      type: Function,
    },
    updateAttributes: {
      type: Function,
    },
  },

  data() {
    return {};
  },

  watch: {},
  mounted() {},

  computed: {},

  methods: {
    onChange() {
      if (this.editor.isEditable) {
        this.updateAttributes({
          completed: !this.node.attrs.completed,
        });

        const props = {
          source: this.$parent.$parent.$vnode.tag,
          value: this.node.attrs.completed,
          action_uuid: this.node.attrs.uuid,
          meeting_id: this.editor.options.meetingId,
          inRelatedMeeting: this.editor.options.inRelatedMeeting,
          isPrivate: this.editor.options.isPrivate,
        };
        // console.log('track props', props);
        window.meetric.track('Toggle action status', props);
      }
    },
  },
};
</script>
<style></style>
