import { Fragment, Slice } from 'prosemirror-model';
import { canSplit } from 'prosemirror-transform';
import { TextSelection } from 'prosemirror-state';
import { getNodeType } from '@tiptap/core';

// Split action_item or agenda_item
export const splitSpecialItem = (typeOrName) => ({
  tr,
  state,
  dispatch,
  editor,
}) => {
  const type = getNodeType(typeOrName, state.schema);
  // console.log('type', type, state.schema);
  const { $from, $to } = state.selection;
  // console.log('from,to', $from, $to);

  const node = state.selection.node;
  // console.log('node', node);

  if ((node && node.isBlock) || $from.depth < 2 || !$from.sameParent($to)) {
    return false;
  }

  const grandParent = $from.node(-1);
  // console.log('grandparent', grandParent);
  if (grandParent.type !== type) {
    return false;
  }

  const isInList =
    $from.node(-2)?.type == getNodeType('listItem', state.schema);
  // console.log('inList', isInList);

  const extensionAttributes = editor.extensionManager.attributes;

  if (
    $from.parent.content.size === 0 &&
    $from.node(-1).childCount === $from.indexAfter(-1)
  ) {
    // console.log('in the if');
    // In an empty block. If this is a nested list, the wrapping
    // list item should be split. Otherwise, bail out and let next
    // command handle lifting.
    if (
      $from.depth === 2 ||
      $from.node(-3).type !== type ||
      $from.index(-2) !== $from.node(-2).childCount - 1
    ) {
      return false;
    }

    if (dispatch) {
      let wrap = Fragment.empty;
      // eslint-disable-next-line
      const depthBefore = $from.index(-1) ? 1 : $from.index(-2) ? 2 : 3;

      // Build a fragment containing empty versions of the structure
      // from the outer list item to the parent node of the cursor
      for (let d = $from.depth - depthBefore; d >= $from.depth - 3; d -= 1) {
        wrap = Fragment.from($from.node(d).copy(wrap));
      }

      // eslint-disable-next-line
      const depthAfter =
        $from.indexAfter(-1) < $from.node(-2).childCount
          ? 1
          : $from.indexAfter(-2) < $from.node(-3).childCount
          ? 2
          : 3;

      // Add a second list item with an empty default start node
      const newNextTypeAttributes = getSplittedAttributes(
        extensionAttributes,
        $from.node().type.name,
        $from.node().attrs
      );
      const nextType =
        type.contentMatch.defaultType?.createAndFill(newNextTypeAttributes) ||
        undefined;

      wrap = wrap.append(
        Fragment.from(type.createAndFill(null, nextType) || undefined)
      );

      const start = $from.before($from.depth - (depthBefore - 1));

      tr.replace(
        start,
        $from.after(-depthAfter),
        new Slice(wrap, 4 - depthBefore, 0)
      );

      let sel = -1;

      tr.doc.nodesBetween(start, tr.doc.content.size, (n, pos) => {
        if (sel > -1) {
          return false;
        }

        if (n.isTextblock && n.content.size === 0) {
          sel = pos + 1;
        }
      });

      if (sel > -1) {
        tr.setSelection(TextSelection.near(tr.doc.resolve(sel)));
      }

      tr.scrollIntoView();
    }

    return true;
  }

  tr.delete($from.pos, $to.pos);

  let types = [];
  let level = 0;
  if (isInList) {
    types = [
      {
        type: getNodeType('listItem', state.schema),
        attrs: {},
      },
      { type, attrs: {} },
    ];
    level = 3;
  } else {
    types = [{ type, attrs: {} }];
    level = 2;
  }

  // console.log('types', types);
  // console.log('split', level, canSplit(tr.doc, $from.pos, level));

  if (!canSplit(tr.doc, $from.pos, level)) {
    // console.log('cant split');
    return false;
  }

  if (dispatch) {
    tr.split($from.pos, level, types).scrollIntoView();
  }

  return true;
};

function getSplittedAttributes(extensionAttributes, typeName, attributes) {
  return Object.fromEntries(
    Object.entries(attributes).filter(([name]) => {
      const extensionAttribute = extensionAttributes.find((item) => {
        return item.type === typeName && item.name === name;
      });

      if (!extensionAttribute) {
        return false;
      }

      return extensionAttribute.attribute.keepOnSplit;
    })
  );
}
