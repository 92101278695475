import { Extension } from '@tiptap/core';
import { Suggestion } from '../Plugins/Suggestion';

export default Extension.create({
  name: 'slashCommands',

  addOptions() {
    return {
      suggestion: {
        suggestionType: 'command',
        char: '/',
        command: ({ editor, range, props }) => {
          props.item.command({ editor, range });
        },
        items: ({ query }) => {
          const items = [
            {
              nameList: 'Action item',
              idList: 'action_item',
              command: ({ editor, range }) => {
                editor.chain().focus().deleteRange(range).setActionItem().run();
              },
            },
            {
              nameList: 'Agenda item',
              idList: 'agenda_item',
              command: ({ editor, range }) => {
                editor.chain().focus().deleteRange(range).setAgendaItem().run();
              },
            },
            {
              nameList: 'Bullet list',
              idList: 'bulletList',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .unsetActionItem()
                  .unsetAgendaItem()
                  .command(({ tr, commands }) => {
                    if (
                      tr.curSelection.$from.node(-2)?.type?.name == 'bulletList'
                    ) {
                      return false;
                    }

                    return commands.toggleBulletList();
                  })
                  .run();
              },
            },
            {
              nameList: 'Numbered list',
              idList: 'orderedList',
              command: ({ editor, range }) => {
                editor
                  .chain()
                  .focus()
                  .deleteRange(range)
                  .unsetActionItem()
                  .unsetAgendaItem()
                  .command(({ tr, commands }) => {
                    if (
                      tr.curSelection.$from.node(-2)?.type?.name ==
                      'orderedList'
                    ) {
                      return false;
                    }

                    return commands.toggleOrderedList();
                  })
                  .run();
              },
            },
          ].filter((item) =>
            item.nameList.toLowerCase().startsWith(query.toLowerCase())
          );

          return { all: items };
        },
        shouldSuggest: ($from) => {
          // only in empty paragraph
          // that is new link, empty list item, empty action, empty agenda
          return (
            $from.parent.type.name == 'paragraph' &&
            $from.parent.childCount == 1 && // ensure only one child - text
            $from.parentOffset == $from.parent.nodeSize - 2 && // there is no text after cursor
            $from.parent.textContent[0] == '/' // we are at the begining
          );
        },
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});
