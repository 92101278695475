import { Extension } from '@tiptap/core';
import { SpecialItemPaste } from '../Plugins/SpecialItemPaste';
import { SpecialItemTransactions } from '../Plugins/SpecialItemTransactions';
import { splitSpecialItem } from '../Commands/splitSpecialItem';
import { getNodeType } from '@tiptap/core';

// Special Item is parent of action_item and agenda_item
// it is separate Extension so it is loaded once per editor instead of for each

const SpecialItemExtension = Extension.create({
  name: 'specialItem-extension',

  addCommands() {
    return {
      toggleSpecialItem: (name) => ({ commands, state }) => {
        return commands.toggleWrap(getNodeType(name, state.schema));
      },
      splitSpecialItem: (name) => (params) => {
        return splitSpecialItem(name)(params);
      },
      deleteSpecialItem: (name) => (params) => {
        const $pos = params.tr.selection.$anchor;
        const grandParent = $pos.node(-1);
        if (grandParent.type !== getNodeType(name, params.state.schema)) {
          return false;
        }

        if ($pos.parentOffset == 0) {
          const chain = params.chain();
          if (params.editor.isActive('heading', { level: 1 })) {
            chain.toggleHeading({ level: 1 });
          } else if (params.editor.isActive('heading', { level: 2 })) {
            chain.toggleHeading({ level: 2 });
          }
          //backspace at the begining of AI
          return chain.toggleSpecialItem(name).run();
        }
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      SpecialItemPaste(),
      SpecialItemTransactions({ editor: this.editor }),
    ];
  },
});

export default SpecialItemExtension;
