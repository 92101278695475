import { Extension } from '@tiptap/core';
import { Decoration, DecorationSet } from 'prosemirror-view';
import { Plugin } from 'prosemirror-state';
import { imagePluginKey } from '../Nodes/Image';

const Placeholder = Extension.create({
  name: 'placeholder',

  addOptions: {
    emptyEditorClass: 'is-editor-empty',
    emptyNodeClass: 'is-empty',
    emptyNodeText: 'Write something …',
    showOnlyWhenEditable: true,
    showOnlyCurrent: true,
  },

  addProseMirrorPlugins() {
    return [
      new Plugin({
        props: {
          decorations: ({ doc, selection }) => {
            const active =
              this.editor.isEditable || !this.options.showOnlyWhenEditable;
            const { anchor } = selection;
            const decorations = [];

            if (!active) {
              return;
            }

            doc.descendants((node, pos) => {
              const hasAnchor = anchor >= pos && anchor <= pos + node.nodeSize;
              const isEmpty = !node.isLeaf && !node.childCount;

              if ((hasAnchor || !this.options.showOnlyCurrent) && isEmpty) {
                const classes = [this.options.emptyNodeClass];

                if (this.editor.isEmpty) {
                  // when image is being uploaded, editor is still empty but we don't want to show message
                  const imageDecos = imagePluginKey.getState(
                    this.editor.view.state
                  );
                  const uploading =
                    imageDecos.find(
                      selection.$anchor.pos,
                      selection.$anchor.pos
                    ).length > 0;
                  if (!uploading) classes.push(this.options.emptyEditorClass);
                }

                const decoration = Decoration.node(pos, pos + node.nodeSize, {
                  class: classes.join(' '),
                  'data-empty-text':
                    this.editor?.options?.emptyEditorText ||
                    this.options.emptyNodeText,
                });

                decorations.push(decoration);
              }

              return false;
            });

            return DecorationSet.create(doc, decorations);
          },
        },
      }),
    ];
  },
  addKeyboardShortcuts() {
    return {
      Escape: () =>
        this.editor.commands.command(({ editor, commands }) => {
          if (editor.isEmpty) {
            return commands.blur();
          }
          return false;
        }),
    };
  },
});

export default Placeholder;
