<template>
  <!-- <node-view-wrapper> -->
  <node-view-wrapper
    class="flex agenda-item flex-grow"
    :data-type="node.type.name"
    :data-uuid="node.attrs.uuid"
    :data-completed="node.attrs.completed.toString()"
    :class="{
      completed: node.attrs.completed,
      'agenda-item-empty': node.content.size <= 2,
      'with-h1': hasH1,
      'with-h2': hasH2,
    }"
  >
    <MRadioCheck
      data-drag-handle
      class="agenda-radio"
      contenteditable="false"
      v-bind:value="node.attrs.completed"
      :disabled="!editor.isEditable"
      @input="onChange"
    ></MRadioCheck>
    <node-view-content
      class="agenda-content"
      data-recording-sensitive
      :contenteditable="editor.isEditable"
    />
  </node-view-wrapper>
  <!-- </node-view-wrapper> -->
</template>

<script>
import { NodeViewWrapper, NodeViewContent } from '@tiptap/vue-2';
import MRadioCheck from '@/components/UI/MRadioCheck';

export default {
  components: { MRadioCheck, NodeViewWrapper, NodeViewContent },
  props: {
    editor: {
      type: Object,
    },
    node: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    getPos: {
      type: Function,
    },
    updateAttributes: {
      type: Function,
    },
  },

  data() {
    return { hasH1: false, hasH2: false };
  },

  watch: {
    'node.firstChild.attrs.level': function () {
      // watch level so we can catch all changes: p->h1, p->h2, h1->h2 and vice versa

      this.hasHeadings();
    },
  },
  mounted() {
    this.hasHeadings();
  },
  computed: {},

  methods: {
    hasHeadings() {
      // we need some delay otherwise cursor jumps outside of agenda item
      setTimeout(() => {
        if (this.node.firstChild.type.name === 'heading') {
          this.hasH1 = this.node.firstChild.attrs.level == 1 ? true : false;
          this.hasH2 = this.node.firstChild.attrs.level == 2 ? true : false;
        } else {
          this.hasH1 = false;
          this.hasH2 = false;
        }
      }, 20);
    },
    onChange() {
      if (this.editor.isEditable) {
        this.updateAttributes({
          completed: !this.node.attrs.completed,
        });

        const props = {
          source: this.$parent.$parent.$vnode.tag,
          value: this.node.attrs.completed,
          action_uuid: this.node.attrs.uuid,
          meeting_id: this.editor.options.meetingId,
          inRelatedMeeting: this.editor.options.inRelatedMeeting,
          isPrivate: this.editor.options.isPrivate,
        };
        // console.log('track props', props);
        window.meetric.track('Toggle agenda item status', props);
      }
    },
  },
};
</script>
<style></style>
