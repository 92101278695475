import { Link as TiptapLink } from '@tiptap/extension-link';
import { Plugin, PluginKey } from 'prosemirror-state';
import { find } from 'linkifyjs';

const Link = TiptapLink.extend({
  addOptions: {
    errorCallback: () => {},
    openOnClick: true,
    linkOnPaste: true,
    HTMLAttributes: {
      target: '_blank',
      rel: 'noopener noreferrer nofollow',
      'data-recording-sensitive': '',
    },
  },

  addProseMirrorPlugins() {
    const plugins = [];

    if (this.options.openOnClick) {
      plugins.push(
        new Plugin({
          key: new PluginKey('handleClickLink'),
          props: {
            handleClick: (view, pos, event) => {
              const attrs = this.editor.getAttributes('link');
              const link = event.target?.closest('a');

              if (link && attrs.href) {
                window.open(attrs.href, attrs.target);

                return true;
              }

              return false;
            },
          },
        })
      );
    }

    if (this.options.linkOnPaste) {
      plugins.push(
        new Plugin({
          key: new PluginKey('handlePasteLink'),
          props: {
            handlePaste: (view, event, slice) => {
              const { state } = view;
              const { selection, doc } = state;
              const { empty } = selection;

              if (empty) {
                return false;
              }

              let textContent = '';

              slice.content.forEach((node) => {
                textContent += node.textContent;
              });

              const link = find(textContent).find(
                (item) => item.isLink && item.value === textContent
              );

              if (!textContent || !link) {
                return false;
              }

              let hasImage = false;
              doc.nodesBetween(selection.from, selection.to, (node) => {
                if (node.type.name == 'image') hasImage = true;
              });

              if (hasImage) {
                this.options.errorCallback({ type: 'image' });
              } else {
                this.editor.commands.setMark(this.type, {
                  href: textContent,
                });
              }
              return true;
            },
          },
        })
      );
    }

    return plugins;
  },
});

export default Link;
