import { Plugin } from 'prosemirror-state';
import { uuid as uuidGen } from 'vue-uuid';
import { setReminder } from '@/api/Cherry';

export function SpecialItemTransactions({ editor }) {
  return new Plugin({
    // automatically add uuid to agenda_item without uuid (new one)
    // automatically add uuid to action_item without uuid (new one)
    // automatically use first mention name as action owner
    // automatically use first datetag as action date
    appendTransaction: (transactions, oldState, newState) => {
      const docChanges =
        transactions.some((transaction) => transaction.docChanged) &&
        !oldState.doc.eq(newState.doc);
      if (!docChanges) return;

      const newTr = newState.tr;
      let shouldDispatch = false;
      let modified = false;

      newState.doc.descendants((node0, pos0) => {
        if (!!node0.type && node0.type.name === 'action_item') {
          modified = false;
          let {
            uuid,
            owner,
            dueDate,
            remind,
            remindDate,
            completed,
            ...rest
          } = node0.attrs;

          // add uuid if not exists
          if (uuid === undefined || uuid === null || uuid === '') {
            // Adds a unique id to a node
            uuid = uuidGen.v4();
            modified = true;
            // action_item without uuid means it was jsut created
            const props = {
              action_uuid: uuid,
              meeting_id: editor.options.meetingId,
              isPrivate: editor.options.isPrivate,
            };
            window.meetric.track('Create action', props);
          }

          // fix for when completed is string instead of bool
          if (typeof completed == 'string') {
            modified = true;
            completed = completed == 'true';
          }

          // save first mention in action_item as an owner
          let mentionFound = 0;
          let dateFound = 0;

          node0.descendants((node) => {
            if (
              !!node.type &&
              node.type.name === 'mention' &&
              mentionFound === 0
            ) {
              mentionFound++;
              if (owner != node.attrs.id) {
                owner = node.attrs.id;
                modified = true;

                const props = {
                  action_uuid: uuid,
                  meeting_id: editor.options.meetingId,
                  isPrivate: editor.options.isPrivate,
                  value: 'email',
                };
                window.meetric.track('Action owner updated', props);
              }
            } else if (
              !!node.type &&
              node.type.name === 'datetag' &&
              dateFound === 0
            ) {
              dateFound++;
              if (dueDate != node.attrs.id) {
                dueDate = node.attrs.id;
                modified = true;

                const props = {
                  action_uuid: uuid,
                  meeting_id: editor.options.meetingId,
                  isPrivate: editor.options.isPrivate,
                  value: node.attrs.id,
                };
                window.meetric.track('Action due date updated', props);
              }
            }
          });
          // no mention found but there is an owner in action_item set
          if (mentionFound === 0 && !(owner === '' || owner === null)) {
            owner = null;
            remind = false;
            remindDate = null;
            modified = true;

            const props = {
              action_uuid: uuid,
              meeting_id: editor.options.meetingId,
              isPrivate: editor.options.isPrivate,
              value: '',
            };
            window.meetric.track('Action owner updated', props);
          }
          // no datetag found but there is a datetag in action_item set
          if (dateFound === 0 && !(dueDate === '' || dueDate === null)) {
            dueDate = null;
            remind = false;
            remindDate = null;
            modified = true;

            const props = {
              action_uuid: uuid,
              meeting_id: editor.options.meetingId,
              isPrivate: editor.options.isPrivate,
              value: '',
            };
            window.meetric.track('Action due date updated', props);
          }

          if (modified) {
            // action_item uuid, owner or dueDate changed
            if (!!owner && !!dueDate) {
              const {
                summary,
                meetingId,
                calendarId,
                isPrivate,
              } = editor.options;
              if (remindDate) {
                // not first time, owner/duedate might have been changed
                const newRemindDate = remindBeforeDue(dueDate);
                if (remindDate != newRemindDate) {
                  remindDate = newRemindDate;
                  setActionReminder(
                    remindDate,
                    summary,
                    calendarId,
                    meetingId,
                    uuid,
                    isPrivate
                  );
                }
              } else {
                // no remindDate == action with owner and duedate created
                remind = true; // we want bell ON by default
                remindDate = remindBeforeDue(dueDate);
                setActionReminder(
                  remindDate,
                  summary,
                  calendarId,
                  meetingId,
                  uuid,
                  isPrivate
                );
              }
            }

            newTr.setNodeMarkup(pos0, undefined, {
              uuid: uuid,
              owner: owner,
              dueDate: dueDate,
              remind: remind,
              remindDate: remindDate,
              completed: completed,
              ...rest,
            });
            shouldDispatch = true;
          }
        } else if (!!node0.type && node0.type.name === 'agenda_item') {
          let { uuid, ...rest } = node0.attrs;
          // add uuid if not exists

          if (uuid === undefined || uuid === null || uuid === '') {
            // Adds a unique id to a node
            uuid = uuidGen.v4();
            // agenda_item without uuid means it was jsut created
            const props = {
              agenda_uuid: uuid,
              meeting_id: editor.options.meetingId,
              isPrivate: editor.options.isPrivate,
            };
            window.meetric.track('Create agenda item', props);

            newTr.setNodeMarkup(pos0, undefined, {
              uuid: uuid,
              ...rest,
            });
            shouldDispatch = true;
          }
        }
      });

      if (shouldDispatch) {
        return newTr;
      }
    },
  });
}

function remindBeforeDue(dueDate) {
  return new Date(
    new Date(dueDate).setDate(new Date(dueDate).getDate() - 2)
  ).toISOString();
}

function setActionReminder(
  remindDate,
  summary,
  calId,
  eventId,
  uuid,
  isPrivate
) {
  setReminder(
    {
      remind_at: remindDate,
      meeting_summary: summary,
      private: isPrivate,
    },
    calId,
    eventId,
    uuid
  );
}
