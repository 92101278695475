<template>
  <node-view-wrapper
    as="span"
    class="datetag text-grey2 dark:text-grey4"
    :class="{ readonly: !editor.isEditable }"
    contenteditable="false"
    data-recording-sensitive
    :data-id="node.attrs.id"
    :data-label="node.attrs.label"
    :data-type="node.type.name"
    >{{ extension.options.renderLabel({ node }) }}</node-view-wrapper
  >
</template>

<script>
import { NodeViewWrapper } from '@tiptap/vue-2';

import Litepicker from 'litepicker';
import moment from 'moment';

export default {
  props: {
    editor: {
      type: Object,
    },
    node: {
      type: Object,
    },
    extension: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
    getPos: {
      type: Function,
    },
    updateAttributes: {
      type: Function,
    },
  },
  data() {
    return {
      datepickerPopup: null,
      popupOpen: false,
      dateClicked: false,
    };
  },
  components: {
    NodeViewWrapper,
  },
  watch: {
    selected: function (val) {
      // console.log('sel', val);
      if (!this.editor.isEditable) return;
      if (val) {
        this.dateClicked = true;
        this.showDatepicker();
      } else {
        this.destroyDatepicker();
      }
    },
  },
  computed: {},
  methods: {
    formatDate(date) {
      return moment(date).format(
        this.extension.options.dateFormat || 'MM/DD/YYYY'
      );
    },
    showDatepicker() {
      if (this.popupOpen) {
        this.destroyDatepicker();
        return;
      }

      const startDate = this.node.attrs.id;
      const nodeDOM = this.editor.view.nodeDOM(this.getPos());
      const _this = this;
      this.datepickerPopup = new Litepicker({
        element: nodeDOM,
        startDate: startDate,
        onSelect: function (date1) {
          _this.updateAttributes({
            id: moment(date1).format('YYYY-MM-DD'),
            label: 'by ' + moment(date1).format('dddd'),
          });
          // when select date, stay in editor and move cursor after date

          _this.editor.commands.setTextSelection(_this.getPos() + 2);
          _this.editor.commands.focus();
        },
        onHide: function () {
          _this.destroyDatepicker();
        },
      });

      this.datepickerPopup.show();
      document.getElementsByClassName('litepicker')[0].tabIndex = 0;
      document
        .getElementsByClassName('litepicker')[0]
        .classList.add('focus:outline-none');
      document.getElementsByClassName('litepicker')[0].focus();
      this.popupOpen = true;
      document
        .getElementsByClassName('litepicker')[0]
        .addEventListener('keydown', this.onKey);
    },
    destroyDatepicker() {
      if (this.datepickerPopup) {
        document.removeEventListener('keydown', this.onKey);
        this.datepickerPopup.destroy();
        this.datepickerPopup = null;
        this.popupOpen = false;
        this.dateClicked = false;
      }
    },
    onKey(e) {
      if (
        e.which == 13 ||
        e.keyCode == 13 ||
        e.which == 27 ||
        e.keyCode == 27
      ) {
        e.preventDefault();
        if (this.getPos() != undefined) {
          this.editor.commands.focus();
          this.editor.commands.setTextSelection(this.getPos() + 2);
        } else {
          this.destroyDatepicker();
        }

        return false; // returning false will prevent the event from bubbling up.
      } else {
        return true;
      }
    },
  },
  mounted() {},
};
</script>
<style scoped>
.datetag {
  white-space: nowrap;
}
.datetag:hover:not(.readonly) {
  @apply cursor-pointer text-opacity-75;
}
</style>
