import { Node, mergeAttributes } from '@tiptap/core';
import { Suggestion } from '../Plugins/Suggestion';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import MentionView from './Views/Mention';
// import { InputRule } from 'prosemirror-inputrules';

const Mention = Node.create({
  name: 'mention',

  addOptions() {
    return {
      HTMLAttributes: { class: 'mention' },
      renderLabel({ options, node }) {
        return `${options.suggestion.char}${node.attrs.label ?? node.attrs.id}`;
      },
      myEmail: '',
      suggestion: {
        char: '@',
        command: ({ editor, range, props }) => {
          // increase range.to by one when the next node is of type "text"
          // and starts with a space character
          const nodeAfter = editor.view.state.selection.$to.nodeAfter;
          const overrideSpace = nodeAfter?.text?.startsWith(' ');

          if (overrideSpace) {
            range.to += 1;
          }

          editor
            .chain()
            .focus()
            .insertContentAt(range, [
              {
                type: this.name,
                attrs: props,
              },
              {
                type: 'text',
                text: ' ',
              },
            ])
            .run();
        },
        allow: ({ editor, range }) => {
          const $from = editor.state.doc.resolve(range.from);
          const type = editor.schema.nodes[this.name];
          const allow = !!$from.parent.type.contentMatch.matchType(type);

          return allow;
        },
        allowSpaces: true,
        matchLimitFn: (match) => {
          // this fn further limit if suggestion regex match or not
          const splittedBySpace = match ? match.query.split(' ') : [];
          const spaceCnt = match ? splittedBySpace.length - 1 : 0;
          return spaceCnt <= 1; // max 1 space
        },
        suggestionType: 'mention',
      },
    };
  },

  group: 'inline',

  inline: true,

  selectable: true,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-id'),
        renderHTML: (attributes) => {
          if (!attributes.id) {
            return {};
          }

          return {
            'data-id': attributes.id,
          };
        },
      },

      label: {
        default: null,
        parseHTML: (element) => element.getAttribute('data-label'),
        renderHTML: (attributes) => {
          if (!attributes.label) {
            return {};
          }

          return {
            'data-label': attributes.label,
          };
        },
      },
    };
  },

  addNodeView() {
    return VueNodeViewRenderer(MentionView);
  },

  parseHTML() {
    return [
      {
        tag: `span[data-type="${this.name}"]`,
      },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(
        { 'data-type': this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
        { class: node.attrs.id == this.options.myEmail ? 'myself' : '' }
      ),
      this.options.renderLabel({
        options: this.options,
        node,
      }),
    ];
  },

  renderText({ node }) {
    return this.options.renderLabel({
      options: this.options,
      node,
    });
  },

  addKeyboardShortcuts() {
    return {
      Backspace: () =>
        this.editor.commands.command(({ tr, state }) => {
          let isMention = false;
          const { selection } = state;
          const { empty, anchor } = selection;

          if (!empty) {
            return false;
          }

          state.doc.nodesBetween(anchor - 1, anchor, (node, pos) => {
            if (node.type.name === this.name) {
              isMention = true;
              tr.insertText(
                this.options.suggestion.char || '',
                pos,
                pos + node.nodeSize
              );

              return false;
            }
          });

          return isMention;
        }),
    };
  },

  addCommands() {
    return {
      addMention: () => ({ state, dispatch }) => {
        const { selection } = state;
        const position = selection.$from.pos;
        const char = selection.$cursor.doc.textBetween(
          position - 1,
          position,
          '\0',
          '\0'
        );

        let addChar = '';
        // add space in front of matching char
        if (!(char === ' ' || selection.$from.start() === position))
          addChar = ' ';

        const tr = state.tr.insertText(
          addChar + this.options.suggestion.char,
          position
        );
        dispatch(tr);
      },
    };
  },

  addProseMirrorPlugins() {
    return [
      Suggestion({
        editor: this.editor,
        ...this.options.suggestion,
      }),
    ];
  },
});

export default Mention;
