import AgendaItemView from './Views/AgendaItem';
import { VueNodeViewRenderer } from '@tiptap/vue-2';
import { wrappingInputRule } from '@tiptap/core';
import SpecialItem from './SpecialItem';

const AgendaItem = SpecialItem.extend({
  name: 'agenda_item',
  group: 'agendablock',
  content: 'paragraph|heading',

  addNodeView() {
    return VueNodeViewRenderer(AgendaItemView);
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => {
        const processed = this.editor.commands.splitSpecialItem(this.name);
        if (processed) {
          if (this.editor.isActive('heading', { level: 1 })) {
            this.editor.commands.toggleHeading({ level: 1 });
          } else if (this.editor.isActive('heading', { level: 2 })) {
            this.editor.commands.toggleHeading({ level: 2 });
          }
        }
        return processed;
      },
      Backspace: () => {
        return this.editor.commands.deleteSpecialItem(this.name);
      },
    };
  },
  addInputRules() {
    return [wrappingInputRule({ find: /^\s*(\(\))$/, type: this.type })];
  },
  addCommands() {
    return {
      toggleAgendaItem: () => ({ editor, chain }) => {
        let ch = chain();
        if (editor.isActive('action_item')) {
          // if we toggle in action item, untoggle first
          ch = ch.toggleActionItem();
        }
        return ch
          .toggleWrap(this.type)
          .command(({ tr, commands, editor }) => {
            let mainPos = -1;
            let datetagText = '';
            tr.doc.descendants((node, pos) => {
              if (node.type.name == 'agenda_item') {
                node.descendants((node0, pos0) => {
                  if (node0.type.name == 'datetag') {
                    mainPos = pos0 + pos + node0.nodeSize;
                    datetagText = getExtensionOptions(
                      editor,
                      'datetag'
                    ).renderLabel({
                      node: node0,
                    });
                  }
                });
                return false;
              }
            });

            if (mainPos === -1) return true;
            else
              return commands.insertContentAt(
                { from: mainPos, to: mainPos + 1 },
                datetagText,
                { updateSelection: false }
              );
          })
          .run();
      },
      // set to agendaItem or do nothing
      setAgendaItem: () => ({ editor, commands }) => {
        if (editor.isActive('agenda_item')) {
          return false;
        }
        return commands.toggleAgendaItem();
      },
      // unset agendaItem or do nothing
      unsetAgendaItem: () => ({ editor, commands }) => {
        if (editor.isActive('agenda_item')) {
          return commands.toggleAgendaItem();
        }
        return false;
      },
    };
  },
});

function getExtensionOptions(editor, name) {
  const extension = editor.extensionManager.extensions.find(
    (extension) => extension.name === name
  );

  return extension.options;
}

export default AgendaItem;
